import React from 'react';
import styled from 'styled-components';
import SEO from '../components/SEO';
import Layout from '../components/Layout';

export default function Home() {
  return (
    <Layout>
      <SEO title="Coming Soon" />
      <Title>🪵 Loglife 🪵</Title>
      <Description>A brand new approach to emotional mindfulness.</Description>
      <Details>Coming Soon!</Details>
    </Layout>
  );
}

const Title = styled.h2`
  font-weight: bolder;
`;

const Description = styled.div`
  font-style: italic;
  margin-bottom: 12px;
`;

const Details = styled.div`
  font-size: 18px;
`;
